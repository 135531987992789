import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

	protected baseUrl: string;
	protected httpHeaders: HttpHeaders;

	constructor(protected http: HttpClient,
				protected logger: LoggerService) {
		this.logger.d("rest-api.service: " + "constructor begin");
		this.httpHeaders = new HttpHeaders();
		this.setHeaderKeyAndValue("Accept-Language", "es-ar");
		// this.setHeaderKeyAndValue("Access-Control-Allow-Origin", "*");
		this.logger.d("rest-api.service: " + "constructor end");
	}	

	init(baseUrl: string){
		this.baseUrl = baseUrl;
	}

	setHeaderKeyAndValue(key: string, value: string) {
		this.httpHeaders = this.httpHeaders.set(key, value);
		this.logger.d("rest-api.service: " + "stored key: " + key);
	}

	get(endpoint: string, params?: any): Promise<any> {
		let endpointUrl = this.getEndpointUrl(endpoint);
		this.logger.d("rest-api.service: " + "get");
		this.logger.d("rest-api.service: " + "url: " + endpointUrl);
		this.logger.d("rest-api.service: " + "headers: ");
		for (const k of this.httpHeaders.keys()) {
			this.logger.d("rest-api.service: " + "		key: " + k);
			this.logger.d("rest-api.service: " + "		value: " + this.httpHeaders.get(k));			
		}
		
		let httpParams: HttpParams = this.getProcessedParams(params);
		return this.http.get(endpointUrl, { "headers": this.httpHeaders, "params": httpParams }).toPromise();
	}

	getBlob(endpoint: string, params?: any): Promise<any> {
		let endpointUrl = this.getEndpointUrl(endpoint);
		this.logger.d("rest-api.service: " + "get");
		this.logger.d("rest-api.service: " + "url: " + endpointUrl);
		this.logger.d("rest-api.service: " + "headers: ");
		let blobHttpHeaders = this.httpHeaders.append('Content-Type', 'application/octet-stream');
		
		for (const k of blobHttpHeaders.keys()) {
			this.logger.d("rest-api.service: " + "		key: " + k);
			this.logger.d("rest-api.service: " + "		value: " + blobHttpHeaders.get(k));			
		}
		let httpParams: HttpParams = this.getProcessedParams(params);
		return this.http.get(endpointUrl, { "headers": blobHttpHeaders, "params": httpParams, "responseType": "blob" }).toPromise();
	}

	getText(endpoint: string, params?: any): Promise<any> {
		let endpointUrl = this.getEndpointUrl(endpoint);
		this.logger.d("rest-api.service: " + "get");
		this.logger.d("rest-api.service: " + "url: " + endpointUrl);
		this.logger.d("rest-api.service: " + "headers: ");
		let textHttpHeaders = this.httpHeaders.append('Content-Type', 'text/html');
		
		for (const k of textHttpHeaders.keys()) {
			this.logger.d("rest-api.service: " + "		key: " + k);
			this.logger.d("rest-api.service: " + "		value: " + textHttpHeaders.get(k));			
		}
		let httpParams: HttpParams = this.getProcessedParams(params);
		return this.http.get(endpointUrl, { "headers": textHttpHeaders, "params": httpParams, "responseType": "text" }).toPromise();
	}

	// post(endpoint: string, body: any, params?: any): Observable<any>{
	// 	let endpointUrl = this.getEndpointUrl(endpoint);
	// 	this.logger.d("rest-api.service: " + "post");
	// 	this.logger.d("rest-api.service: " + "url: " + endpointUrl);
	// 	this.logger.d("rest-api.service: " + "headers: ");
	// 	for (const k of this.httpHeaders.keys()) {
	// 		this.logger.d("rest-api.service: " + "		key: " + k);
	// 		this.logger.d("rest-api.service: " + "		value: " + this.httpHeaders.get(k));			
	// 	}

	// 	let httpParams: HttpParams = this.getProcessedParams(params);		
	// 	this.logger.d("rest-api.service: " + "		body: " + JSON.stringify(body, null, 2));
		
	// 	return this.http.post(endpointUrl, body, {"headers": this.httpHeaders, "params": httpParams, observe: 'response'});
	// }

	post(endpoint: string, body: any, params?: any): Promise<any>{
		let endpointUrl = this.getEndpointUrl(endpoint);
		this.logger.d("rest-api.service: " + "post");
		this.logger.d("rest-api.service: " + "url: " + endpointUrl);
		this.logger.d("rest-api.service: " + "headers: ");
		for (const k of this.httpHeaders.keys()) {
			this.logger.d("rest-api.service: " + "		key: " + k);
			this.logger.d("rest-api.service: " + "		value: " + this.httpHeaders.get(k));			
		}

		let httpParams: HttpParams = this.getProcessedParams(params);		
		this.logger.d("rest-api.service: " + "		body: " + JSON.stringify(body, null, 2));
		
		return this.http.post(endpointUrl, body, {"headers": this.httpHeaders, "params": httpParams}).toPromise();
	}
	
	put(endpoint: string, body: any, params?: any): Promise<any>{
		let endpointUrl = this.getEndpointUrl(endpoint);
		this.logger.d("rest-api.service: " + "put");
		this.logger.d("rest-api.service: " + "url: " + endpointUrl);
		this.logger.d("rest-api.service: " + "headers: ");
		for (const k of this.httpHeaders.keys()) {
			this.logger.d("rest-api.service: " + "		key: " + k);
			this.logger.d("rest-api.service: " + "		value: " + this.httpHeaders.get(k));			
		}

		let httpParams: HttpParams = this.getProcessedParams(params);		
		this.logger.d("rest-api.service: " + "		body: " + JSON.stringify(body, null, 2));
		
		return this.http.put(endpointUrl, body, {"headers": this.httpHeaders, "params": httpParams }).toPromise();
	}

	delete(endpoint: string, body: any, params?: any): Promise<any>{
		let endpointUrl = this.getEndpointUrl(endpoint);
		this.logger.d("rest-api.service: " + "delete");
		this.logger.d("rest-api.service: " + "url: " + endpointUrl);
		this.logger.d("rest-api.service: " + "headers: ");
		for (const k of this.httpHeaders.keys()) {
			this.logger.d("rest-api.service: " + "		key: " + k);
			this.logger.d("rest-api.service: " + "		value: " + this.httpHeaders.get(k));			
		}

		// let httpParams: HttpParams = this.getProcessedParams(params);		
		let httpOptions = {
			headers: this.httpHeaders, body: body
		};
		
		return this.http.delete(endpointUrl, httpOptions).toPromise();
	}

	patch(endpoint: string, body: any, params?: any) {
		let endpointUrl = this.getEndpointUrl(endpoint);
		this.logger.d("rest-api.service: " + "patch");
		this.logger.d("rest-api.service: " + "url: " + endpointUrl);
		this.logger.d("rest-api.service: " + "headers: ");
		for (const k of this.httpHeaders.keys()) {
			this.logger.d("rest-api.service: " + "		key: " + k);
			this.logger.d("rest-api.service: " + "		value: " + this.httpHeaders.get(k));			
		}

		let httpParams: HttpParams = this.getProcessedParams(params);		
		this.logger.d("rest-api.service: " + "		body: " + JSON.stringify(body, null, 2));
		
		return this.http.patch(endpointUrl, body, {"headers": this.httpHeaders, "params": httpParams }).toPromise();
	}

	getEndpointUrl(endpoint: string): string{
		let endpointUrl: string;
		if(endpoint.indexOf("http") == -1){
			endpointUrl = this.baseUrl + '/' + endpoint;
		}else{
			endpointUrl = endpoint;
		}
		return endpointUrl;
	}

	getProcessedParams(params?: any): HttpParams{
		let httpParams: HttpParams = new HttpParams();
		if (params != undefined && params != null) {
			for (var k in params) {
				if (params.hasOwnProperty(k)) {
					httpParams = httpParams.set(k, params[k]);
				}
			}
			this.logger.d("rest-api.service: " + "params: " + JSON.stringify(httpParams.keys(), null, 2));
		}
		return httpParams;
	}

	isReady(){
		return this.httpHeaders.keys().indexOf("uuid") != -1;
	}

	printHeaders(){
		for (const k of this.httpHeaders.keys()) {
			this.logger.d("rest-api.service: " + "		key: " + k);
			this.logger.d("rest-api.service: " + "		value: " + this.httpHeaders.get(k));			
		}
	}

	getHeaders(){
		return this.httpHeaders;
	}

	getBaseUrl(){
		return this.baseUrl;
	}
}
