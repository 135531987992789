import { Injectable } from '@angular/core';
import { User } from './user.model';
import { AppInitService } from '../service/app-init.service';
import { RestAuthApiService } from '../service/rest-api/rest-auth-api.service';
import { RestApiService } from '../service/rest-api/rest-api.service';
import { LoggerService } from '../service/logger/logger.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	authEndpoint = "be/auth";
	passwordResetEndpoint = "auth/user-password-reset";
	passwordResetUuidEndpoint = "auth/user-password-reset-uuid";
	passwordRecoverEmailEndpoint = "auth/send-password-reset-email";

	user: User;

	constructor(protected apiService: RestApiService,
		protected logger: LoggerService,
		protected appInitService: AppInitService,
		protected restAuthApiService: RestAuthApiService) { }

	isLoggedIn() {
		return this.user != null && this.user.token != null;
	}

	// store the URL so we can redirect after logging in
	redirectUrl: string;

	/**
	 * return redirectUrl
	 * @param username 
	 * @param password 
	 */
	login(username: string, password: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			let data = {
				username: username,
				password: password
			};
			this.apiService.printHeaders();
			this.logger.i("auth.service: " + JSON.stringify(data, null, 2));
			this.apiService.post(this.authEndpoint, data)
				.then(res => {
					if (res.status == "ok") {
						this.logger.i("auth.service: " + JSON.stringify(res, null, 2));
						this.user = res.data;
						this.user.username = username;
						
						if(this.user.expired){
							resolve("");
						} else {							
							
							this.restAuthApiService.setHeaderKeyAndValue("Authorization", "Bearer " + this.user.token);
							this.appInitService.init(this.redirectUrl)
								.then(res => {								
									resolve(res);
								})
								.catch(error => {
									this.logger.e("auth.service: " + JSON.stringify(error, null, 2));
									if (error.statusText != null && error.statusText == "Unknown Error") {
										reject({ message: "El servicio no está disponible. Por favor, inténtelo mas tarde.", fatal: true });
									}
									reject({ message: error.headers.get("x-reason") });
								});

						}

					}
					if (res.status == "error") {
						// this.snackBar.showError(res.errors.general);
						reject({ message: res.errors.general });
					}
				})
				.catch(error => {
					this.logger.e("auth.service: " + JSON.stringify(error, null, 2));
					if (error.statusText != null && error.statusText == "Unknown Error") {
						reject({ message: "El servicio no está disponible. Por favor, inténtelo mas tarde.", fatal: true });
					}
					reject({ message: error.headers.get("x-reason") });
				});
		});
	}

	logout(): void {
		this.apiService.setHeaderKeyAndValue("Authorization", "");
		this.user = null;
		this.appInitService.setReady(false);
	}

	getFullUserName() {
		return this.user.username;
	}

	getUserData(): any {
		return this.user;
	}

	passwordReset(password: string): Promise<any> {
		return new Promise((resolve, reject) => {
			let data = {
				password: password
			};
			this.apiService.setHeaderKeyAndValue("Authorization", "Bearer " + this.user.token);
			this.apiService.post(this.passwordResetEndpoint, data)
				.then(res => {
					if (res.status == "ok") {
						this.apiService.setHeaderKeyAndValue("Authorization", "");
						resolve(true);
					}
					if (res.status == "error") {
						reject({ message: res.errors.general });
					}
				})
				.catch(error => {
					this.logger.e("auth.service: " + JSON.stringify(error, null, 2));
					if (error.statusText != null && error.statusText == "Unknown Error") {
						reject({ message: "El servicio no está disponible. Por favor, inténtelo mas tarde.", fatal: true });
					}
					reject({ message: error.headers.get("x-reason") });
				});
		});
	}

	passwordResetUuid(uuid: string, password: string): Promise<any> {
		return new Promise((resolve, reject) => {
			let data = {
				uuid: uuid,
				password: password
			};
			this.apiService.post(this.passwordResetUuidEndpoint, data)
				.then(res => {
					if (res.status == "ok") {
						this.logger.i("auth.service: " + JSON.stringify(res, null, 2));
						resolve(res.data);
					}
					if (res.status == "error") {
						reject({ message: res.errors.general });
					}
				})
				.catch(error => {
					this.logger.e("auth.service: " + JSON.stringify(error, null, 2));
					if (error.statusText != null && error.statusText == "Unknown Error") {
						reject({ message: "El servicio no está disponible. Por favor, inténtelo mas tarde.", fatal: true });
					}
					reject({ message: error.headers.get("x-reason") });
				});
		});
	}

	sendPasswordRecoveryEmail(username: string, email: string, env: string): Promise<any> {
		return new Promise<void>((resolve, reject) => {
			let data = {
				username: username,
				email: email,
				env: env
			};
			this.logger.i("auth.service: " + JSON.stringify(data, null, 2));
			this.apiService.post(this.passwordRecoverEmailEndpoint, data)
				.then(res => {
					if (res.status == "ok") {
						this.logger.i("auth.service: " + JSON.stringify(res, null, 2));
						resolve(res.data);
					}
					if (res.status == "error") {
						reject({ message: res.errors.general });
					}
				})
				.catch(error => {
					this.logger.e("auth.service: " + JSON.stringify(error, null, 2));
					if (error.statusText != null && error.statusText == "Unknown Error") {
						reject({ message: "El servicio no está disponible. Por favor, inténtelo mas tarde.", fatal: true });
					}
					reject({ message: error.headers.get("x-reason") });
				});
		});
	}
}
