import { Injectable, EventEmitter } from '@angular/core';
import { LoggerService } from '../../service/logger/logger.service';

@Injectable({
	providedIn: 'root'
})
export class LoadingIndicatorService {

	shown: boolean = false;
	loadingObservable: EventEmitter<boolean>;
	
	constructor(private logger: LoggerService) { 
		this.logger.i("loading-indicator.service: constructed");
		this.loadingObservable = new EventEmitter();
	}

	show(){
		if(!this.shown){
			this.shown = true;
			this.loadingObservable.emit(true);
		}
	}

	hide(){
		if(this.shown){
			this.shown = false;
			this.loadingObservable.emit(false);
		}
	}
}
