import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoggerService } from './logger/logger.service';

@Injectable({
	providedIn: 'root'
})
export class AppInitService {

	protected appReady = new Subject<boolean>();
	appReady$ = this.appReady.asObservable();

	constructor(protected logger: LoggerService) {
		this.logger.i("app-init.service: constructed");
	}

	/**
	 * invocado luego del login en el AuthService para inicializar la aplicación
	 * puede hacerse override
	 * 
	 * @param redirectUrl
	 * @returns 
	 */
	init(redirectUrl?: string): Promise<any> {
		this.logger.i("app-init.service: " + "begin init");
		this.setReady(true);
		if(redirectUrl){
			return Promise.resolve(redirectUrl);
		}
		return Promise.resolve("home");
	}

	setReady(value: boolean) {
		this.appReady.next(value);
	}
}
