import { Injectable, EventEmitter } from '@angular/core';
import { LoggerService } from '../../service/logger/logger.service';

@Injectable({
	providedIn: 'root'
})
export class SnackBarService {

	snackBarObservable: EventEmitter<any>;

	constructor(private logger: LoggerService) {
		this.logger.i("snack-bar.service: constructed");
		this.snackBarObservable = new EventEmitter();
	}

	show(message: string){
		this.snackBarObservable.emit({message:message});
	}

	showError(message: string){
		this.snackBarObservable.emit({message: message, error: true});
	}
}
