import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoggerService } from '../service/logger/logger.service';

@Injectable({
	providedIn: 'root'
})
export class DialogGuard implements CanDeactivate<any> {

	constructor(private dialog: MatDialog,
		private logger: LoggerService) {
	}

	canDeactivate(component: any,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		// this.logger.i("dialog.guard: " + "parent " + currentRoute.parent.url);	
		// this.logger.i("dialog.guard: " + "currentroute " + currentRoute.url);	
		// this.logger.i("dialog.guard: " + "current " + currentState.url);
		// this.logger.i("dialog.guard: " + "next " + nextState.url);
		// this.logger.i("dialog.guard: " + "dialog guard " + this.dialog.openDialogs.length);
		if (this.dialog.openDialogs.length > 0) {
			// this.logger.i("auth.guard: " + "close: " + this.dialog.openDialogs[this.dialog.openDialogs.length - 1].id);
			this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();
			this.logger.i("dialog.guard: " + "dialog guard after closed " + this.dialog.openDialogs.length);
			// return this.router.parseUrl(currentState.url);
			//se pushea el state actual, ya que el backbutton hace un pop
			history.pushState(null, null, currentState.url);
			return false;
		}

		return true;
	}

}
