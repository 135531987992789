import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LoggerService } from '../logger/logger.service';
import { Subject } from 'rxjs';
import { RestApiService } from './rest-api.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RestAuthApiService extends RestApiService {

	protected authenticatedSource = new Subject<any>();
	authenticated$ = this.authenticatedSource.asObservable();

	constructor(protected http: HttpClient,
				protected router: Router,
				protected logger: LoggerService) {
		super(http, logger);
		this.logger.i("rest-api-auth.service: constructed");
	}

	override get(endpoint: string, params?: any): Promise<any> {
		return new Promise((resolve, reject) => {
			super.get(endpoint, params)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				if(error.statusText == "Unknown Error"){
					this.authenticatedSource.next(false);
					this.router.navigate(["login"], {queryParams: {status: 'La sesión expiró. Volvé a iniciar sesión.'}});
					reject(error);
				}else{
					reject(error);
				}
			});
		});
	}

	override post(endpoint: string, body: any, params?: any): Promise<any> {
		return new Promise((resolve, reject) => {
			super.post(endpoint, body, params)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				if(error.statusText == "Unknown Error"){
					this.authenticatedSource.next(false);
					this.router.navigate(["login"], {queryParams: {status: 'La sesión expiró. Volvé a iniciar sesión.'}});
					reject(error);
				}else{
					reject(error);
				}
			});
		});

	}

	override put(endpoint: string, body: any, params?: any): Promise<any> {
		return new Promise((resolve, reject) => {
			super.put(endpoint, body, params)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				if(error.statusText == "Unknown Error"){
					this.authenticatedSource.next(false);
					this.router.navigate(["login"], {queryParams: {status: 'La sesión expiró. Volvé a iniciar sesión.'}});
					reject(error);
				}else{
					reject(error);
				}
			});
		});
	}

	override delete(endpoint: string, body: any, params?: any): Promise<any> {
		return new Promise((resolve, reject) => {
			super.delete(endpoint, body, params)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				if(error.statusText == "Unknown Error"){
					this.authenticatedSource.next(false);
					this.router.navigate(["login"], {queryParams: {status: 'La sesión expiró. Volvé a iniciar sesión.'}});
					reject(error);
				}else{
					reject(error);
				}
			});
		});
	}

	override patch(endpoint: string, body: any, params?: any): Promise<any> {
		return new Promise((resolve, reject) => {
			super.patch(endpoint, body, params)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				if(error.statusText == "Unknown Error"){
					this.authenticatedSource.next(false);
					this.router.navigate(["login"], {queryParams: {status: 'La sesión expiró. Volvé a iniciar sesión.'}});
					reject(error);
				}else{
					reject(error);
				}
			});
		});
	}
}
