import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LoggerService {

	d(value: string): void {

	}

	i(value: string) {

	}

	w(value: string) {

	}

	e(value: string) {

	}
}
